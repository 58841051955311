// last two/three might be wrong but idk yet
export const CONTENTFUL_SECTION_TYPES = {
  API_REVIEWS: "ContentfulApiReviews",
  CONTENT: "ContentfulSectionContent",
  CTA: "ContentfulSectionCallToAction",
  DIVIDER: "ContentfulSectionDivider",
  GRID: "ContentfulSectionGrid",
  INSTAGRAM: "ContentfulSectionInstagram",
  LIST: "ContentfulSectionList",
  SLIDER: "ContentfulSectionSlider",
}
